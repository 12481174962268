body{
  background-color: #C2C0BF;
}

.container{
  max-width: 750px;
}

.form{
  font-family: 'Lato', sans-serif;
  color: black;
}

.form-control-lg{
  width: 100%;
}

.dropdown-content{
  margin-bottom: 75px;
}

.btn-lg{
  display: inline;
  margin-left: auto;
}

.btn-group{
    display: inline-block;
    margin-bottom: 15px;
}

.dropdown{
  margin-top: 10px;
  color: blue;
}

.card-body{
  background-color: #C2C0BF;
  border: none;
  z-index: 1;
}

.upper{
  text-align: center;
  margin-bottom: 20px;
}

.input-group{
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.12);
}

@media only screen and (max-width: 460px) {
    .form-control-lg {
      max-width: 380px
    }
  }

  @media only screen and (max-width: 375px) {
    .form-control-lg {
      max-width: 345px
    }
  }

  @media only screen and (max-width: 360px) {
    .form-control-lg {
      max-width: 330px
    }
  }

  @media only screen and (max-width: 320px) {
    .form-control-lg {
      max-width: 290px
    }
  }

#search-box{
    background-color: white;
    text-align: center;
    color: black;
}

#search-box::placeholder {
    color: #C2C0BF;
    color: black;
}
