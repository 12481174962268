.nav-container{
    width: 100%;
}

.navbar{
    background-color: #A31F34 !important;
    font-family: 'Lato', sans-serif;
    border-bottom: 1px ridge black;
}

.navbar-brand{
    color: white;
    font-size: 1.5em;
}

.nav-link{
    padding: 0;
    display: inline;
    text-align: right;
    color: white;
    padding-bottom: 4px;
}

#hamburger1{
    color: white;
}

#login {
    border-right: 1px solid black;
    color:white;
}

#saved {
    color:white;
}

.navbar-toggler {
    padding: 0;
}