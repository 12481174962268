.flyout {
	display:flex;
	flex-direction: column;
	min-height:100vh;
	justify-content: space-between;
}

.home-feature-box .fa {
	font-size:6rem;
}

.home-feature-box span {
	display: block;
	color:#111;
	font-weight:bold;
	margin-top:1.5rem;
}

.example-components-list li > a {
  color: #495057;
}

.example-components-list li:last-child > a {
	border-bottom:0;
}

.example-components-list li > a .fa {
	color:rgba(0,0,0,.35);
	float:right;
}

.nav-container{
    width: 100%;
}

.navbar{
    background-color: #A31F34 !important;
    font-family: 'Lato', sans-serif;
    border-bottom: 1px ridge black;
}

.navbar-brand{
    color: white;
    font-size: 1.5em;
}

.nav-link{
    padding: 0;
    display: inline;
    text-align: right;
    color: white;
    padding-bottom: 4px;
}

#hamburger1{
    color: white;
}

#login {
    border-right: 1px solid black;
    color:white;
}

#saved {
    color:white;
}

.navbar-toggler {
    padding: 0;
}
.nav-jumbo {
  background-color: #C2C0BF;
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  font-size: 1.5em;
  position: relative;
}
body{
  background-color: #C2C0BF;
}

.container{
  max-width: 750px;
}

.form{
  font-family: 'Lato', sans-serif;
  color: black;
}

.form-control-lg{
  width: 100%;
}

.dropdown-content{
  margin-bottom: 75px;
}

.btn-lg{
  display: inline;
  margin-left: auto;
}

.btn-group{
    display: inline-block;
    margin-bottom: 15px;
}

.dropdown{
  margin-top: 10px;
  color: blue;
}

.card-body{
  background-color: #C2C0BF;
  border: none;
  z-index: 1;
}

.upper{
  text-align: center;
  margin-bottom: 20px;
}

.input-group{
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.12);
}

@media only screen and (max-width: 460px) {
    .form-control-lg {
      max-width: 380px
    }
  }

  @media only screen and (max-width: 375px) {
    .form-control-lg {
      max-width: 345px
    }
  }

  @media only screen and (max-width: 360px) {
    .form-control-lg {
      max-width: 330px
    }
  }

  @media only screen and (max-width: 320px) {
    .form-control-lg {
      max-width: 290px
    }
  }

#search-box{
    background-color: white;
    text-align: center;
    color: black;
}

#search-box::-webkit-input-placeholder {
    color: #C2C0BF;
    color: black;
}

#search-box::-moz-placeholder {
    color: #C2C0BF;
    color: black;
}

#search-box::-ms-input-placeholder {
    color: #C2C0BF;
    color: black;
}

#search-box::placeholder {
    color: #C2C0BF;
    color: black;
}

.card{
    max-width: 720px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Lato', sans-serif;
    z-index: 0;
    background-color: #C2C0BF;
}

.pCard {
  padding-right: 20px;
}

#resultsCards {
    background-color: #C2C0BF;
}

.card-body{
    z-index: 1;
}

@media only screen and (max-width: 770px) {
    .card {
      max-width: 660px;
      padding-left: 5%;
      padding-right: 5%;
    }

    .pCard {
      padding-right: 0px;
    }

    #resultsBtns {
      padding-left: 7px;
      padding-right: 7px;
    }

    h4 {
        font-size: 1.1rem;
    }
  }

  @media only screen and (max-width: 659px) {
    .card {
      max-width: 459px;
      padding-left: 5%;
      padding-right: 5%;
    }

    .pCard {
      padding-right: 0px;
    }

    #resultsBtns {
      padding-left: 7px;
      padding-right: 7px;
    }

    h4 {
        font-size: 1.1rem;
    }
  }

  @media only screen and (max-width: 460px) {
    .card {
      max-width: 380px;
      padding-left: 5%;
      padding-right: 5%;
    }

    .pCard {
      padding-right: 0px;
    }

    #resultsBtns {
      padding-left: 7px;
      padding-right: 7px;
    }

    h4 {
        font-size: 1.1rem;
    }
  }

  @media only screen and (max-width: 375px) {
    .card {
      max-width: 345px;
      padding-left: 5%;
      padding-right: 5%;
    }

    .pCard {
      padding-right: 0px;
    }

    #resultsBtns {
      padding-left: 7px;
      padding-right: 7px;
    }

    h4 {
        font-size: 1.1rem;
    }
  }

  @media only screen and (max-width: 360px) {
    .card {
      max-width: 330px;
      padding-left: 5%;
      padding-right: 5%;
    }

    .pCard {
      padding-right: 0px;
    }

    #resultsBtns {
      padding-left: 7px;
      padding-right: 7px;
    }

    h4 {
        font-size: 1.1rem;
    }
  }

  @media only screen and (max-width: 320px) {
    .card {
      max-width: 290px;
      padding-left: 5%;
      padding-right: 5%;
    }

    .pCard {
      padding-right: 0px;
    }

    #resultsBtns {
      padding-left: 7px;
      padding-right: 7px;
    }

    h4 {
        font-size: 1.1rem;
    }
  }
.paginationBtns{
  text-align: center;
  margin-bottom: 75px;
}
.footer{
    background-color: #C2C0BF!important;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    text-align: center;
    margin-top: 20px;
    z-index: 2;
}

.title{
    font-size: 0.8em;
    margin: auto;
}
.resources{
    margin-bottom: 50px;
    padding: 2em 1em;
    font-family: 'Lato', sans-serif;
}

.jumbotron{
    background-color: #C2C0BF;
}

.resources h3{
    font-size: 3.5em;
}

.text-left h4{
    margin-left: 10px;
}

.resources a:link{
    font-size: 1em;
}




.term-body{
    padding: 50px;
}
body{
    background-color: #8A8B8C;
}

.container{
  max-width: 600px;
}

.btn-lg{
  display: inline;
}

.loginBtn{
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 460px) {

    .loginCard {
      max-width:400px;
      margin-left: 10%;
      margin-right: 10%;
    }

    #loggedinBtns {
      margin: 0;
    }
    
    h4 {
      font-size: 1.1rem;
    }
  }


.container{
  max-width: 750px;
}

