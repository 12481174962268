body{
    background-color: #8A8B8C;
}

.container{
  max-width: 600px;
}

.btn-lg{
  display: inline;
}

.loginBtn{
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 460px) {

    .loginCard {
      max-width:400px;
      margin-left: 10%;
      margin-right: 10%;
    }

    #loggedinBtns {
      margin: 0;
    }
    
    h4 {
      font-size: 1.1rem;
    }
  }

