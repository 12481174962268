.footer{
    background-color: #C2C0BF!important;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    text-align: center;
    margin-top: 20px;
    z-index: 2;
}

.title{
    font-size: 0.8em;
    margin: auto;
}